
import * as strapiRuntime$CYx8tIjAOc from '/tmp/build/node_modules/@nuxt/image/dist/runtime/providers/strapi'
import * as ipxRuntime$VBw5Xov9sL from '/tmp/build/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "cover": {
      "modifiers": {
        "fit": "inside",
        "format": "png",
        "width": 540,
        "height": 540
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "localhost:1337",
    "back.darkevent.ru"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['strapi']: { provider: strapiRuntime$CYx8tIjAOc, defaults: {"baseURL":"https://back.darkevent.ru"} },
  ['ipx']: { provider: ipxRuntime$VBw5Xov9sL, defaults: undefined }
}
        